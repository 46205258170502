import {useEffect, useState} from "react";
import axios from "axios";
import axiosInstance from "../api";
import {useParams} from "react-router-dom";
import Map from "../map/Map";
import {Button, Descriptions, Spin} from "antd";
import dayjs from "dayjs";
import {ReloadOutlined} from "@ant-design/icons";


export default function () {

    const {id} = useParams();
    const [info, setInfo] = useState({})
    const [loading, setLoading] = useState(true)
    const fetch = () => {
        setLoading(true)
        axiosInstance.get(`/vehicle/${id}/location`)
            .then(({data}) => setInfo(data ?? {}))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetch();
    }, []);

    return <Spin spinning={loading}>
        {(!loading && info.Latitude) || loading ? <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
            <Descriptions size={"small"} layout={"vertical"} column={2}>
                <Descriptions.Item span={2} label={"Indirizzo"}>{info.Address?.AddressLine1}</Descriptions.Item>
                <Descriptions.Item label={"Stato veicolo"}>{info.DisplayState}</Descriptions.Item>
                <Descriptions.Item
                    label={"Ultimo aggiornamento"}>{dayjs(info.UpdateUTC).utcOffset("+02:00").format("DD/MM/YYYY HH:mm:ss")}</Descriptions.Item>
            </Descriptions>
            <Button icon={<ReloadOutlined/>} onClick={fetch}>Aggiorna</Button>
            <Map style={{alignSelf: 'center'}} location={info}/>
        </div> : "Dispositivo GPS non installato"}
    </Spin>
}