import {useNavigate} from "react-router-dom";
import {Button, Typography} from "antd";
const {Title} = Typography;
export default function NavBackTitle({title}){
    const navigate = useNavigate();

    return <span>
        <Button onClick={() => navigate(-1)}>B</Button>
        <Title level={3}>{title}</Title>
    </span>
}