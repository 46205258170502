import dayjs from "dayjs";
import { Table } from "antd";
import Title from "antd/lib/typography/Title";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { useParams } from "react-router-dom";


const columns = [
    {
        key: 'Data',
        title: 'Data',
        dataIndex: 'dateTime',
        width: 150,
        sorter: (a, b) => a.dateTime.diff(b.dateTime),
        render: col => col.format("DD/MM/YYYY HH:mm")
    },
    {
        key: 'amount',
        title: 'Importo',
        dataIndex: 'amount',
        sorter: (a, b) => b.amount - a.amount,
        width: 100,
        align: 'end',
        render: txt => txt.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
    },
    {
        key: 'entrance',
        title: 'Entrata',
        sorter: (a, b) => a.entrance.localeCompare(b.entrance),
        width: 200,
        dataIndex: 'entrance',
    },
    {
        key: 'exit',
        dataIndex: 'exit',
        sorter: (a, b) => a.exit.localeCompare(b.exit),
        width: 200,
        title: 'Uscita'
    }
]

export default function TelepassTransactions() {
    const {id} = useParams()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        if (id) {
            setLoading(true)
            axiosInstance.get('telepass', { params: { id } })
                .then(({ data }) => {
                    const dataSource = data.map(x => ({
                        ...x,
                        dateTime: dayjs(x.date).utcOffset(x.offset),
                        key: x.id
                    })).sort((a, b) => b.dateTime.diff(a.dateTime))
                    setData(dataSource)
                })
                .finally(() => setLoading(false))
        }
    },[id])

    return <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
        <Title level={5} style={{ marginTop: 0 }}>Movimenti</Title>
        <Table loading={loading} size={"small"} tableLayout={"fixed"} dataSource={data} columns={columns} />
    </div>
}
