import { Button, Col, Divider, Dropdown, Popconfirm, Row, Space, Table } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import { downloadFile, formatDateTime } from "../util";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "../api";
import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import Toolbar from "../common/Toolbar";
import PdfViewer from "../common/PdfViewer";
import { UserInfoContext } from "../common/FetchUserInfo";

export default function Documents() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [sp, setSP] = useSearchParams();
    const userPermissions = useContext(UserInfoContext)?.permissions ?? [];
    const query = sp.get("q") ?? "";

    const loadData = () => {
        setLoading(true);
        axiosInstance
            .get(`document`, { params: { vehicle: id } })
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        setSelectedRowKeys([]);
    }, [sp]);

    const deleteDoc = (key) => {
        axiosInstance
            .delete(`/document/${key[0]}`)
            .then(() => setSelectedRowKeys([]))
            .then(() => loadData());
    };

    const columns = [
        {
            key: "id",
            dataIndex: "id",
            fixed: "left",
            width: 70,
            sorter: (a, b) => a.id - b.id,
            title: "ID",
        },
        {
            key: "description",
            dataIndex: "description",
            fixed: "left",
            ellipsis: true,
            sorter: (a, b) => a.description.localeCompare(b.description),
            width: 200,
            title: "Descrizione",
            render: (x, row) => (
                <Link replace onClick={() => setSelectedAttachment(`/document/${row.id}/file`)}>
                    <u>{x}</u>
                </Link>
            ),
        },
        {
            key: "date",
            width: 100,
            dataIndex: "date",
            render: (x) => (x ? dayjs(x).format("DD/MM/YYYY") : ""),
            sorter: (a, b) => dayjs(a.date).diff(dayjs(b.date)),
            title: "Data",
        },
        {
            key: "dueDate",
            dataIndex: "dueDate",
            width: 100,
            render: (x) => (x ? dayjs(x).format("DD/MM/YYYY") : ""),
            sorter: (a, b) => dayjs(a.date).diff(dayjs(b.date)),
            title: "Scadenza",
        },
        {
            key: "createdBy",
            dataIndex: "createdBy",
            render: (x) => x.fullName,
            width: 150,
            ellipsis: true,
            title: "Creato da",
        },
        {
            key: "creationDate",
            dataIndex: "creationDate",
            width: 150,
            ellipsis: true,
            render: formatDateTime,
            title: "Data creazione",
        },
        {
            key: "updatedBy",
            dataIndex: "updatedBy",
            render: (x) => x.fullName,
            width: 150,
            ellipsis: true,
            title: "Modificato da",
        },
        {
            key: "updateDate",
            dataIndex: "updateDate",
            render: formatDateTime,
            width: 150,
            ellipsis: true,
            title: "Data modifica",
        },
    ];

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <PdfViewer url={selectedAttachment} onClose={() => setSelectedAttachment(null)} />
            <Search
                allowClear
                defaultValue={sp.get("q")}
                style={{ width: 200 }}
                placeholder={"Cerca"}
                onSearch={(txt) => {
                    sp.set("q", txt);
                    setSP(sp, { replace: true });
                }}
            />
            <Toolbar
                selectedKeys={selectedRowKeys}
                addAction={
                    userPermissions.includes("document.create")
                        ? () => navigate(`/documents/new?vehicle=${id}`)
                        : null
                }
                deleteAction={
                    userPermissions.includes("document.delete")
                        ? deleteDoc 
                        : null
                }
                extra={(keys) => (
                    <Button
                        type={"text"}
                        disabled={selectedRowKeys.length === 0}
                        icon={<DownloadOutlined />}
                        onClick={() => downloadFile(`/document/${keys[0]}/file`)}
                    >
                        Scarica
                    </Button>
                )}
            />

            <Table
                rowSelection={{
                    type: "radio",
                    selectedRowKeys: selectedRowKeys,
                    onChange: setSelectedRowKeys,
                }}
                loading={loading}
                scroll={{ x: 1140 }}
                tableLayout={"fixed"}
                size={"small"}
                columns={columns}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            setSelectedRowKeys([record.key]);
                        }, // click row
                    };
                }}
                dataSource={data
                    .map((x) => ({
                        ...x,
                        key: x.id,
                    }))
                    .filter((x) => x.description.toLowerCase().includes(query.toLowerCase()))}
            />
        </div>
    );
}
