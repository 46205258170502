import axios from "axios";
import {message} from "antd";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.response.use((response) => {
    return response;
}, function (error) {
    const errorMsg = (error.response.data ?? "axios.server.error")

    if (error.response.status === 403) {
        message.error("Permessi insufficienti",5)
    } else {
        message.error("Errore: " + (error.response?.data?.message ?? "axios.server.error"),5)
    }
    return Promise.reject(error)
});


export default axiosInstance
