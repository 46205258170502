import DOMPurify from "dompurify";

const defaultOptions = {
    ALLOWED_TAGS: ["ol", "br", "b", "i", "u", "em", "strong", "a", "li", "ul", "h1", "h2", "h3", "h4", "h5", "p", "i"],
    ALLOWED_ATTR: ["href"],
};

const sanitize = (dirty, options) => ({
    __html: DOMPurify.sanitize(dirty, { ...defaultOptions, ...options }),
});

export default function ({content}) {
    return <div className="html-content" dangerouslySetInnerHTML={sanitize(content, defaultOptions)} />;
}
