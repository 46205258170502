import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import axiosInstance from "../api";

export default function PdfViewer({ url, onClose }) {
    const [attachment, setAttachment] = useState(null);

    useEffect(() => {
        if (attachment) {
            document.body.style.overflow = "hidden";
        } else document.body.style.overflow = "inherit";
    }, [attachment]);

    useEffect(() => {
        if (url) {
            axiosInstance.get(url, { responseType: "blob" }).then(({ data }) => {
                const blob = new Blob([data], { type: "application/pdf" });
                setAttachment(URL.createObjectURL(blob));
            });
        } else setAttachment(null);
    }, [url]);
    return (
        attachment && (
            <Portal>
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        minWidth: "100%",
                        minHeight: "100%",
                        zIndex: 999,
                        display: "flex",
                        flexDirection: "column",
                        background: "rgba(0,0,0,0.9)",
                    }}
                >
                    <div style={{ padding: 20, textAlign: "end" }}>
                        <Button
                            size="large"
                            style={{ color: "white" }}
                            type={"text"}
                            onClick={() => onClose?.()}
                            icon={<CloseOutlined />}
                        >
                            Chiudi
                        </Button>
                    </div>
                    <iframe
                        src={attachment}
                        style={{
                            flexGrow: 1,
                            border: "none",
                            margin: 0,
                            padding: 0,
                        }}
                    />
                </div>
            </Portal>
        )
    );
}
