import { useContext } from "react"
import { UserInfoContext } from "./FetchUserInfo"

export default function PermissionRequired({name,requireIf,children}){
    requireIf = requireIf !== undefined ? requireIf : true
    const user = useContext(UserInfoContext)
    if(requireIf)
        return user.permissions.includes(name) ? children : ""
    else
        return children
}
