import {useEffect, useRef} from "react";
import H from '@here/maps-api-for-javascript';

function getMarkerIcon(color) {
    const svgCircle = `<svg width="120" height="120" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="marker">
                <circle cx="15" cy="15" r="11" fill="${color}" stroke="white" stroke-width="2" />
                </g></svg>`;
    return new H.map.Icon(svgCircle, {
        anchor: {
            x: 10,
            y: 10
        }
    });
}


export default function ({location, style}) {
    const mapRef = useRef(null);
    const map = useRef(null);
    const platform = useRef(null)
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(
        () => {
            // Check if the map object has already been created
            if (!map.current) {
                // Create a platform object with the API key
                platform.current = new H.service.Platform({apiKey});

                const defaultLayers = platform.current.createDefaultLayers({
                    lg: 'it-IT',
                    queryParams: {
                        style: "explore.day",
                        size: 512,
                    },
                });
                // Create a new map instance with the Tile layer, center and zoom level
                const newMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
                    pixelRatio: window.devicePixelRatio || 1,
                    zoom: 16,
                });

                const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(newMap));
                const ui = H.ui.UI.createDefault(newMap, defaultLayers,'it-IT');
                // Set the map object to the reference
                map.current = newMap;
            }else {
                if(location.Latitude && location.Longitude){
                    map.current.removeObjects(map.current.getObjects())
                    map.current.setZoom(16)
                    map.current.addObject(new H.map.Marker({lat: location.Latitude, lng: location.Longitude},{icon: getMarkerIcon('red')}));
                    map.current.setCenter({lat: location.Latitude,lng: location.Longitude})
                }
            }
        },
        // Dependencies array
        [location]
    );

    return <div style={{width: "100%", height: "350px",...style}} ref={mapRef}/>;

}
