import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { Avatar, Button, Col, Row, Space, Table, Tooltip } from "antd";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import BackTitle from "../common/BackTitle";
import {
    ExportOutlined,
    PlusOutlined,
    SearchOutlined,
    SmallDashOutlined,
    UserOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { stringToFile, withTooltip } from "../util";
import PermissionRequired from "../common/PermissionRequired";
import Filters from "./Filters";
import { json2csv } from "json-2-csv";

export default function Vehicles() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") ?? 0;
    const pageSize = searchParams.get("pageSize") ?? 10;

    const sortField = searchParams.get("sortField");
    const sortOrder = searchParams.get("sortOrder");

    const columns = [
        {
            key: "label",
            dataIndex: "label",
            width: 70,
            ellipsis: true,
            title: "Numero",
            sorter: true,
            render: (title, record) => <Link to={"" + record.id}>{withTooltip(title)}</Link>,
        },
        {
            key: "licensePlate",
            dataIndex: "licensePlate",
            width: 80,
            sorter: true,
            ellipsis: true,
            title: "Targa/Matricola",
        },
        {
            key: "category",
            dataIndex: "category",
            width: 100,
            ellipsis: true,
            title: "Categoria",
            render: (x) => x?.name ?? "",
        },
        {
            key: "model",
            dataIndex: "model",
            width: 100,
            ellipsis: true,
            title: "Modello",
            render: (_, record) => `${record.manufacturer ?? ""} ${record.model ?? ""}`,
        },
        {
            key: "driver",
            dataIndex: "driver",
            width: 100,
            ellipsis: true,
            title: "Autista",
            render: (x) => x?.fullName,
        },
        {
            key: "taxDueDate",
            dataIndex: "taxDueDate",
            width: 70,
            ellipsis: true,
            sorter: true,
            title: "Bollo",
            render: (x) => x && dayjs(x).format("DD/MM/YYYY"),
        },
        {
            key: "inspectionDueDate",
            dataIndex: "inspectionDueDate",
            width: 70,
            ellipsis: true,
            sorter: true,
            title: "Revisione",
            render: (x) => x && dayjs(x).format("DD/MM/YYYY"),
        },
    ].map((x) => ({
        ...x,
        sortOrder: sortField === x.dataIndex && sortOrder,
    }));

    useEffect(() => {
        setLoading(true);
        const params = {
            ...[...searchParams.entries()].reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}),
            page,
            pageSize,
        };
        axiosInstance
            .get("vehicle", { params })
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    }, [searchParams]);

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <Row>
                <Col span={12}>
                    <BackTitle backEnabled={false} title={"Veicoli"} padding={0} />
                </Col>
                <Col span={12}>
                    <Space style={{ height: "100%", width: "100%", justifyContent: "end" }}>
                        <PermissionRequired name={"vehicle.create"}>
                            <Button
                                type={"primary"}
                                icon={<PlusOutlined />}
                                onClick={() => navigate("new")}
                                style={{ width: 200 }}
                            >
                                Nuovo veicolo
                            </Button>
                        </PermissionRequired>
                    </Space>
                </Col>
            </Row>
            <Filters />
            <Button
                icon={<ExportOutlined />}
                style={{ width: 150 }}
                onClick={() => {
                    axiosInstance
                        .get("vehicle", { params: { pageSize: 9999 } })
                        .then(({ data }) => {
                            const toExport = data.data.map((x) => ({
                                Numero: x.label,
                                Targa: x.licensePlate,
                                Categoria: x.category?.name,
                                Modello: x.manufacturer + " " + x.model,
                                Anno: x.year,
                                Telaio: x.vin,
                                Autista: x.driver?.fullName,
                                Odometro: x.odometer,
                                'Ore motore': x.hours,
                                Note: x.notes
                            }));
                            stringToFile(
                                json2csv(toExport, { emptyFieldValue: "" }),
                                "veicoli.csv",
                            );
                        });
                }}
            >
                Esporta (CSV)
            </Button>
            <PermissionRequired name={"vehicle.read"}>
                <Table
                    size="small"
                    loading={loading}
                    columns={columns}
                    onChange={(pagination, _, sort) => {
                        if (sort.order) {
                            searchParams.set("sortField", sort.field);
                            searchParams.set("sortOrder", sort.order);
                        } else {
                            searchParams.delete("sortField");
                            searchParams.delete("sortOrder");
                        }
                        searchParams.set("page", "" + (pagination.current - 1));
                        searchParams.set("pageSize", "" + pagination.pageSize);
                        setSearchParams(searchParams, { replace: true });
                    }}
                    tableLayout={"fixed"}
                    scroll={{ x: columns.reduce((acc, x) => acc + x.width, 0) }}
                    pagination={{
                        current: Number(page) + 1,
                        showTotal: (total) => "Totale: " + total,
                        pageSize: pageSize,
                        total: data.count ?? 0,
                    }}
                    dataSource={data.data?.map((x) => ({ ...x, key: x.id })) ?? []}
                />
            </PermissionRequired>
        </div>
    );
}
