import { Button, Form, Input, message, Radio, Space, Transfer } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../api";
import BackTitle from "../common/BackTitle";
import { filterOption, requiredField } from "../util";

const getUnit = (x) =>
    ({
        HOURS_BASED: "Ore motore",
        DISTANCE_BASED: "Km",
        DATE_BASED: "giorni",
    })[x];

export default function NewMaintenancePlan() {
    const [vehicles, setVehicles] = useState([]);
    const [form] = Form.useForm();
    const type = Form.useWatch("type", form);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        axiosInstance.get("vehicle", { params: { pageSize: 9999 } }).then(({ data }) =>
            setVehicles(
                data.data.map((x) => ({
                    key: x.id,
                    title: `${x.label} (${x.licensePlate}) - ${x.manufacturer} ${x.model}`,
                })),
            ),
        );
    }, []);

    const onFinish = (form) => {
        setLoading(true);
        axiosInstance
            .post("maintenancePlan", form)
            .then(() => {
                navigate(-1);
                message.success("Piano di manutenzione creato");
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <BackTitle backEnabled={true} padding={0} title="Nuovo piano di manutenzione"/>
            <Form
                onFinish={onFinish}
                layout="vertical"
                style={{marginTop: 30}}
                form={form}
                initialValues={{ type: "DISTANCE_BASED" }}
            >
                <Form.Item name={"name"} label="Nome" rules={[requiredField]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"type"} label="Tipologia" rules={[requiredField]}>
                    <Radio.Group>
                        <Radio value="DISTANCE_BASED">Basato su distanza</Radio>
                        <Radio value="HOURS_BASED">Basato su ore motore</Radio>
                        <Radio value="DATE_BASED">Basato su data</Radio>
                    </Radio.Group>
                </Form.Item>
                <Space>
                    <Form.Item name={"dueQuantity"} label="Scadenza" rules={[requiredField]}>
                        <Input style={{ width: 200 }} suffix={getUnit(type) ?? ""} />
                    </Form.Item>
                    <Form.Item name={"remindQuantity"} label="Promemoria" rules={[requiredField]}>
                        <Input style={{ width: 200 }} suffix={getUnit(type) ?? ""} />
                    </Form.Item>
                </Space>
                <Form.Item
                    label="Veicoli"
                    name="vehicles"
                    valuePropName="targetKeys"
                    rules={[requiredField]}
                >
                    <Transfer
                        showSearch
                        filterOption={filterOption}
                        listStyle={{ minWidth: 400, minHeight: 300 }}
                        dataSource={vehicles}
                        titles={['Non inclusi','Inclusi']}
                        render={(item) => item.title}
                    />
                </Form.Item>

                <Form.Item style={{ textAlign: "end" }}>
                    <Button
                        style={{ width: 200 }}
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Crea
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
