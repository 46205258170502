import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../api";
import BackTitle from "../common/BackTitle";
import Toolbar from "../common/Toolbar";

const columns = [
    {
        key: "id",
        dataIndex: "id",
        width: 50,
        ellipsis: true,
        title: "Numero",
    },
    {
        key: "name",
        dataIndex: "name",
        ellipsis: true,
        width: 200,
        title: "Nome",
    },
    {
        key: "type",
        dataIndex: "type",
        width: 100,
        ellipsis: true,
        title: "Tipologia",
        render: (x) =>
            ({
                HOUR_BASED: "Basato su ore",
                DISTANCE_BASED: "Basato su distanza",
                DATE_BASED: "Basato su data",
            })[x],
    },
    {
        key: "dueQuantity",
        dataIndex: "dueQuantity",
        width: 100,
        ellipsis: true,
        title: "Scadenza",
    },
    {
        key: "remindQuantity",
        dataIndex: "remindQuantity",
        width: 100,
        ellipsis: true,
        title: "Promemoria",
    },
];

export default function MaintenancePlans() {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        setLoading(true);
        axiosInstance
            .get("maintenancePlan")
            .then(({ data }) => setData(data.map((x) => ({ ...x, key: x.id }))))
            .finally(() => setLoading(false));
    }, []);

    return (
        <Spin spinning={loading}>
            <BackTitle backEnabled={false} padding={0} title="Piani di manutenzione" />
            <Toolbar
                style={{ marginTop: 20 }}
                addAction={() => navigate("new")}
                selectedKeys={selectedRowKeys}
                editAction={(key) => navigate(`${key}/edit`)}
            />
            <Table
                tableLayout="fixed"
                size="small"
                dataSource={data}
                columns={columns}
                scroll={{x: columns.reduce((prev,cur) => prev + cur.width,0)}}
                onRow={record => ({
                    onClick: () => setSelectedRowKeys([record.key])
                })}
                rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys, type: "radio" }}
            />
        </Spin>
    );
}
