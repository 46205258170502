
import {createContext, useEffect, useState} from "react";
import axiosInstance from "../api";
import {Spin} from "antd";


export const UserInfoContext = createContext({})

export default function FetchUserInfo(props){
    const [userInfo,setUserInfo] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axiosInstance.get("me")
            .then(({data}) => setUserInfo(data))
            .finally(() => setLoading(false))
    }, []);

    return <UserInfoContext.Provider value={userInfo}>
        {loading ? <Spin spinning={true}/>  : props.children}
    </UserInfoContext.Provider>
}
