import Keycloak from "keycloak-js";
import axiosInstance from "./api";

export const keycloakInstance = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: 'master',
    clientId: 'lambda',
});

export function keycloack() {
    return keycloakInstance.init({onLoad: "login-required", })
        .then(authenticated => {
            if (authenticated) {
                axiosInstance.defaults.headers.common['Authorization'] = "Bearer " + keycloakInstance.token
                axiosInstance.interceptors.request.use(async config => {
                    const refresh = await keycloakInstance.updateToken(5);
                    axiosInstance.defaults.headers.common['Authorization'] = "Bearer " + keycloakInstance.token
                    config.headers.Authorization = "Bearer " + keycloakInstance.token
                    return config;
                })
            }
        })
}
