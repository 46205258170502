import {Button, DatePicker, Form, Input} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import axiosInstance from "../api";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useState} from "react";
import BackTitle from "../common/BackTitle";
import { requiredField } from "../util";


export default function NewDocument() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [searchParams] = useSearchParams();
    const vehicleId = searchParams.get("vehicle")

    const onFormFinish = (form) => {
        setLoading(true)
        const formData = new FormData();
        if(form.date)
            formData.append("date", form.date.format("YYYY-MM-DD"))
        if(form.dueDate)
            formData.append("dueDate", form.dueDate.format("YYYY-MM-DD"))
        formData.append("file", form.file.file)
        formData.append("description", form.description)
        formData.append("vehicle",vehicleId);
        axiosInstance.post(`document`, formData, {headers: {"Content-Type": 'multipart/form-data'}})
            .then(() => navigate(-1))
            .then(() => setLoading(false))
    }
    return <div style={{display: "flex", flexDirection: "column",rowGap: 16}}>
        <BackTitle title={"Carica documento"} padding={0}/>
        <Form layout={"vertical"} onFinish={onFormFinish}>
            <Form.Item label={"Descrizione"} name={"description"} rules={[requiredField]}>
                <Input/>
            </Form.Item>

            <Form.Item label={"Data"} name={"date"}>
                <DatePicker format={"DD/MM/YYYY"}/>
            </Form.Item>

            <Form.Item label={"Data scadenza"} name={"dueDate"}>
                <DatePicker format={"DD/MM/YYYY"}/>
            </Form.Item>

            <Form.Item label={"File"} name='file' valuePropName={'file'}>
                <Dragger maxCount={1}
                         beforeUpload={() => false}>
                    <p>Trascina qui il file</p>
                    <Button icon={<UploadOutlined/>}>Seleziona file</Button>
                </Dragger>
            </Form.Item>
            <Form.Item style={{textAlign: "end"}}>
                <Button htmlType={"submit"} type={"primary"}>Salva</Button>
            </Form.Item>
        </Form>
    </div> ;
}
