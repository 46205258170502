import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { useForm } from "antd/lib/form/Form";
import { Button, DatePicker, Form, Input, InputNumber, message, Select, Space, Spin } from "antd";
import { filterOption, requiredField } from "../util";
import dayjs from "dayjs";
import BackTitle from "../common/BackTitle";
import TextArea from "antd/lib/input/TextArea";

export default function EditDueDates() {
    const { id } = useParams();
    const [form] = useForm();
    const [types, setTypes] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [vehicle, setVehicle] = useState({});

    useEffect(() => {
        axiosInstance
            .get("employee")
            .then(({ data }) =>
                setEmployees(data.map((x) => ({ label: x.fullName, value: x.id }))),
            );

        axiosInstance
            .get("vehicleCategory")
            .then(({ data }) => setTypes(data.map((x) => ({ label: x.name, value: x.id }))));
        axiosInstance
            .get(`vehicle/${id}`)
            .then(({ data }) => {
                setVehicle(data);
                form.setFieldsValue({
                    taxDueDate: data.taxDueDate && dayjs(data.taxDueDate),
                    inspectionDueDate: data.inspectionDueDate && dayjs(data.inspectionDueDate),
                });
            })
            .finally(() => setLoading(false));
    }, []);

    const onFinish = (form) => {
        form.taxDueDate = form.taxDueDate && form.taxDueDate.format("YYYY-MM-DD");
        form.inspectionDueDate =
            form.inspectionDueDate && form.inspectionDueDate.format("YYYY-MM-DD");

        axiosInstance
            .put(`vehicle/${id}/dueDates`, form)
            .then(() => navigate(-1))
            .then(() => message.success("Veicolo aggiornato con successo"));
    };

    return (
        <Spin spinning={loading}>
            <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <BackTitle
                    title={`Modifica scadenze veicolo ${vehicle.label} (${vehicle.licensePlate})`}
                    padding={0}
                />
                <Form layout={"vertical"} form={form} onFinish={onFinish}>
                    <Form.Item label={"Prossimo bollo"} name={"taxDueDate"}>
                        <DatePicker style={{ width: 200 }} format={"DD/MM/YYYY"} />
                    </Form.Item>
                    <Form.Item label={"Prossima revisione"} name={"inspectionDueDate"}>
                        <DatePicker style={{ width: 200 }} format={"DD/MM/YYYY"} />
                    </Form.Item>

                    <Form.Item style={{ textAlign: "end" }}>
                        <Button loading={loading} htmlType={"submit"} type={"primary"}>
                            Salva
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Spin>
    );
}
