import {Button, DatePicker, Divider, Form, Input, InputNumber, message, Space, Spin} from "antd";
import axiosInstance from "../api";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useForm} from "antd/lib/form/Form";
import dayjs from "dayjs";
import BackTitle from "../common/BackTitle";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { requiredField } from "../util";

export default function EditMaintenance() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(true)
    const [form] = useForm()

    useEffect(() => {
        axiosInstance.get(`/maintenance/${id}`)
            .then(({data}) => form.setFieldsValue({
                ...data,
                date: dayjs(data.date),
                dueDate: data.dueDate ? dayjs(data.dueDate) : undefined
            }))
            .finally(() => setInitialLoading(false))
    }, []);

    const onFormFinish = (form) => {
        form.date = form.date.format("YYYY-MM-DD")
        if(form.dueDate)
            form.dueDate = form.dueDate.format("YYYY-MM-DD")
        axiosInstance.put(`/maintenance/${id}`, form)
            .then(() => navigate(-1))
            .then(() => message.success("Creato con successo"))
            .finally(() => setLoading(false))
    }

    return <Spin spinning={initialLoading}>
        <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
            <BackTitle title={"Modifca manutenzione"} padding={0}/>
            <Form layout={"vertical"} form={form} onFinish={onFormFinish}>
                <Form.Item label={"Data"} name={"date"} rules={[requiredField]}>
                    <DatePicker format={"DD/MM/YYYY"}/>
                </Form.Item>
                <Space direction="horizontal" split={<Divider type="vertical"/>}>
                    <Form.Item label={"Odometro"} name={"odometer"}>
                        <InputNumber style={{ width: 150 }} min={0} suffix="KM" />
                    </Form.Item>
                    <Form.Item label={"Ore motore"} name={"hours"}>
                        <InputNumber style={{ width: 150 }} min={0} suffix="Ore" />
                    </Form.Item>
                </Space>
                <Form.Item label={"Descrizione"} name={"description"} rules={[requiredField]}>
                    <ReactQuill theme="snow"/>
                </Form.Item>
                <Form.Item style={{textAlign: "end"}}>
                    <Button loading={loading} type={"primary"} htmlType={"submit"}>Salva</Button>
                </Form.Item>
            </Form>
        </div>
    </Spin>

}
