import {
    Button,
    Col,
    Descriptions,
    Divider,
    Popconfirm,
    Row,
    Space,
    Table,
    Tooltip,
    Typography,
} from "antd";
import Search from "antd/lib/input/Search";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import axiosInstance from "../api";
import { downloadFile, formatDateTime, textFromHtml } from "../util";
import Highlighter from "react-highlight-words";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import InnerHTML from "../common/InnerHTML";
import PermissionRequired from "../common/PermissionRequired";

const { Text } = Typography;
export default function Maintenances() {
    const { id } = useParams();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const navigate = useNavigate();
    const [sp, setSP] = useSearchParams();
    const query = sp.get("q") ?? "";

    const refresh = () => {
        setLoading(true);
        axiosInstance
            .get(`maintenance`, { params: { vehicle: id } })
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        setExpandedRowKeys([]);
    }, [sp]);

    const columns = [
        {
            key: "id",
            dataIndex: "id",
            fixed: "left",
            width: 50,
            sorter: (a, b) => a.id - b.id,
            title: "ID",
        },
        {
            key: "date",
            width: 100,
            dataIndex: "date",
            sorter: (a, b) => dayjs(a.date).diff(dayjs(b.date)),
            defaultSortOrder: "descend",
            render: (a) => (a ? dayjs(a).format("DD/MM/YYYY") : ""),
            title: "Data",
        },
        {
            key: "odometer",
            dataIndex: "odometer",
            sorter: (a, b) => a.odometer - b.odometer,
            width: 100,
            title: "Odometro",
        },
        {
            key: "hours",
            dataIndex: "hours",
            sorter: (a, b) => a.hours - b.hours,
            width: 100,
            title: "Ore motore",
        },
    ];

    const renderRowContent = (record) => (
        <div
            style={{
                padding: 16,
                background: "white",
                display: "flex",
                flexDirection: "column",
                rowGap: 16,
            }}
        >
            <Descriptions
                style={{ background: "white" }}
                layout={"vertical"}
                size={"small"}
                column={2}
                extra={
                    <Space>
                        <PermissionRequired name="maintenance.update">
                            <Button
                                type={"text"}
                                onClick={() => navigate(`/maintenances/${record.id}/edit`)}
                                icon={<EditOutlined />}
                            >
                                Modifica
                            </Button>
                        </PermissionRequired>
                        <PermissionRequired name="maintenance.delete">
                            <Popconfirm
                                title="Elimina manutenzione"
                                description="Sei sicuro di voler procedere?"
                                onConfirm={() =>
                                    axiosInstance
                                        .delete(`/maintenance/${record.id}`)
                                        .then(() => refresh())
                                }
                                okText="Si"
                                cancelText="No"
                            >
                                <Button type={"text"} danger icon={<DeleteOutlined />}>
                                    Elimina
                                </Button>
                            </Popconfirm>
                        </PermissionRequired>
                    </Space>
                }
                items={[
                    {
                        key: "1",
                        label: "Attività svolta",
                        span: {
                            xs: 2,
                            md: 2,
                            sm: 2,
                        },
                        children: <InnerHTML content={record.description} />,
                        /*children: <Highlighter
                            highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                            searchWords={[query]}
                            autoEscape
                            textToHighlight={record.description}
                        />*/
                    },
                    {
                        key: "1",
                        label: "Creato da",
                        span: {
                            xs: 2,
                            md: 1,
                            sm: 2,
                        },
                        children: record.createdBy?.fullName,
                    },
                    {
                        key: "2",
                        label: "Data creazione",
                        span: {
                            xs: 2,
                            md: 1,
                            sm: 2,
                        },
                        children: formatDateTime(record.creationDate),
                    },
                    {
                        key: "3",
                        label: "Ultima modifica",
                        span: {
                            xs: 2,
                            md: 1,
                            sm: 2,
                        },
                        children: record.updatedBy?.fullName,
                    },
                    {
                        key: "4",
                        span: {
                            xs: 2,
                            md: 1,
                            sm: 2,
                        },
                        label: "Data modifica",
                        children: formatDateTime(record.updateDate),
                    },
                ]}
                title={"Dettaglio manutenzione"}
            />
        </div>
    );

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <Search
                allowClear
                defaultValue={query}
                style={{ width: 200 }}
                placeholder={"Cerca"}
                onSearch={(txt) => {
                    sp.set("q", txt);
                    setSP(sp, { replace: true });
                }}
            />
            <div>
                <Space size={0}>
                    <PermissionRequired name="maintenance.create">
                        <Button
                            type={"primary"}
                            icon={<PlusOutlined />}
                            onClick={() => navigate(`/maintenances/new?vehicle=${id}`)}
                        >
                            Nuovo
                        </Button>
                    </PermissionRequired>
                </Space>
                <Divider style={{ margin: 0, marginTop: 5 }} />
            </div>
            <Table
                scroll={{ x: 270 }}
                tableLayout={"fixed"}
                size={"small"}
                columns={columns}
                loading={loading}
                dataSource={data
                    .map((x) => ({
                        ...x,
                        key: x.id,
                    }))
                    .filter((x) =>
                        textFromHtml(x.description).toLowerCase().includes(query.toLowerCase()),
                    )}
                expandable={{
                    expandedRowRender: renderRowContent,
                    columnWidth: 30,
                    expandedRowKeys: expandedRowKeys,
                    //onExpandedRowsChange: (selected) => setExpandedRowKeys(selected.filter(x => !expandedRowKeys.includes(x)))
                    onExpandedRowsChange: setExpandedRowKeys,
                }}
            />
        </div>
    );
}
