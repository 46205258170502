import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { useForm } from "antd/lib/form/Form";
import { Button, DatePicker, Form, Input, InputNumber, message, Select, Space, Spin } from "antd";
import { filterOption, requiredField } from "../util";
import dayjs from "dayjs";
import NavBackTitle from "../NavBackTitle";
import BackTitle from "../common/BackTitle";
import TextArea from "antd/lib/input/TextArea";

export default function EditVehicle() {
    const { id } = useParams();
    const [form] = useForm();
    const [types, setTypes] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [vehicle, setVehicle] = useState({});

    useEffect(() => {
        axiosInstance
            .get("employee")
            .then(({ data }) =>
                setEmployees(data.map((x) => ({ label: x.fullName, value: x.id }))),
            );

        axiosInstance
            .get("vehicleCategory")
            .then(({ data }) => setTypes(data.map((x) => ({ label: x.name, value: x.id }))));
        axiosInstance
            .get(`vehicle/${id}`)
            .then(({ data }) => {
                setVehicle(data);
                form.setFieldsValue({
                    ...data,
                    registrationDate: data.registrationDate && dayjs(data.registrationDate),
                    driver: data.driver?.id,
                    category: data.category?.id,
                });
            })
            .finally(() => setLoading(false));
    }, []);

    const onFinish = (form) => {
        form.registrationDate = form.registrationDate ? form.registrationDate.format("YYYY-MM-DD") : null;
        axiosInstance
            .put(`vehicle/${id}/info`, form)
            .then(() => navigate(-1))
            .then(() => message.success("Veicolo aggiornato con successo"));
    };

    return (
        <Spin spinning={loading}>
            <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <BackTitle title={`Modifica veicolo ${vehicle.label}`} padding={0} />
                <Form layout={"vertical"} form={form} onFinish={onFinish}>
                    <Form.Item
                        label={"Targa/Matricola"}
                        name="licensePlate"
                        rules={[requiredField]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label={"Categoria"} name={"category"} rules={[requiredField]}>
                        <Select options={types} showSearch filterOption={filterOption} />
                    </Form.Item>
                    <Space block wrap>
                        <Form.Item
                            label={"Produttore"}
                            name={"manufacturer"}
                            rules={[requiredField]}
                        >
                            <Input style={{ width: 200 }} />
                        </Form.Item>
                        <Form.Item label={"Modello"} name={"model"} rules={[requiredField]}>
                            <Input style={{ width: 200 }} />
                        </Form.Item>
                        <Form.Item label={"Anno"} name={"year"}>
                            <InputNumber style={{ width: 100 }} />
                        </Form.Item>
                        <Form.Item label={"Data immatricolazione"} name={"registrationDate"}>
                            <DatePicker style={{ width: 200 }} format={"DD/MM/YYYY"} />
                        </Form.Item>
                        <Form.Item label={"Nr. telaio"} name="vin" style={{ width: "100%" }}>
                            <Input style={{ width: 300 }} />
                        </Form.Item>
                    </Space>
                    <Form.Item label={"Etichetta"} name={"label"}>
                        <Input placeholder="Automatico" />
                    </Form.Item>
                    <Form.Item label={"Autista"} name={"driver"}>
                        <Select
                            options={employees}
                            showSearch
                            allowClear
                            filterOption={filterOption}
                        />
                    </Form.Item>
                    <Form.Item label={"Note"} name={"notes"}>
                        <TextArea />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "end" }}>
                        <Button loading={loading} htmlType={"submit"} type={"primary"}>
                            Salva
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Spin>
    );
}
