import { Button, DatePicker, Divider, Form, Input, InputNumber, message, Space } from "antd";
import axiosInstance from "../api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import BackTitle from "../common/BackTitle";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { requiredField } from "../util";

export default function NewMaintenance() {
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onFormFinish = (form) => {
        form.date = form.date.format("YYYY-MM-DD");
        form.vehicle = searchParams.get("vehicle");
        if (form.dueDate) form.dueDate = form.dueDate.format("YYYY-MM-DD");
        axiosInstance
            .post(`/maintenance`, form)
            .then(() => navigate(-1))
            .then(() => message.success("Creato con successo"))
            .finally(() => setLoading(false));
    };
    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <BackTitle title={"Nuova manutenzione"} padding={0} />
            <Form layout={"vertical"} onFinish={onFormFinish}>
                <Form.Item label={"Data"} name={"date"} rules={[requiredField]}>
                    <DatePicker format={"DD/MM/YYYY"} />
                </Form.Item>
                <Space direction="horizontal" split={<Divider type="vertical"/>}>
                    <Form.Item label={"Odometro"} name={"odometer"}>
                        <InputNumber style={{ width: 150 }} min={0} suffix="KM" />
                    </Form.Item>
                    <Form.Item label={"Ore motore"} name={"hours"}>
                        <InputNumber style={{ width: 150 }} min={0} suffix="Ore" />
                    </Form.Item>
                </Space>
                <Form.Item label={"Descrizione"} name={"description"} rules={[requiredField]}>
                    <ReactQuill theme="snow" />
                </Form.Item>
                <Form.Item style={{ textAlign: "end" }}>
                    <Button loading={loading} type={"primary"} htmlType={"submit"}>
                        Salva
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
