import dayjs from "dayjs";
import { Button, Col, Descriptions, Divider, Row, Space, Spin, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import ProfilePicture from "../common/ProfilePicture";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { SyncOutlined } from "@ant-design/icons";
import BackTitle from "../common/BackTitle";
import PermissionRequired from "../common/PermissionRequired";

const { Link, Title } = Typography;

export default function VehicleInfo() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [data, setData] = useState({});
    const [syncLoading, setSyncLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const refresh = () => {
        setLoading(true);
        axiosInstance
            .get(`vehicle/${id}`)
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        axiosInstance.get(`vehicle/${id}`).then(({ data }) => setData(data));
    }, []);

    const items = [
        {
            label: "Numero",
            children: data.label,
        },
        {
            label: "Targa",
            children: data.licensePlate,
        },
        {
            label: "N. telaio",
            children: data.vin,
        },
        {
            label: "Categoria",
            children: data.category?.name,
        },
        {
            label: "Data immatricolazione",
            children: data.registrationDate
                ? dayjs(data.registrationDate).format("DD/MM/YYYY")
                : "",
        },
        {
            label: "Modello",
            children: (data.manufacturer ?? "") + " " + (data.model ?? ""),
        },
        {
            label: "Anno",
            children: data.year,
        },
        {
            label: "Autista",
            children: data.driver?.fullName,
        },
        {
            label: "Note",
            notes: 3,
            children: data.notes,
        },
    ];
    return (
        <Spin spinning={loading}>
            <BackTitle
                title={
                    <>
                        Veicolo <u>{data.label ?? ""}</u>
                    </>
                }
                padding={0}
            />
            <Row style={{ marginTop: 30 }}>
                <Col xs={24} sm={24} lg={8} style={{ textAlign: "center", paddingBottom: 30 }}>
                    <ProfilePicture id={data?.id} hasImg={data?.photoRef} />
                </Col>
                <Col xs={24} sm={24} lg={16}>
                    <Divider orientation="left" style={{ marginTop: 0 }}>
                        Informazioni
                        <PermissionRequired name={"vehicle.update"}>
                            {" "}
                            <Button onClick={() => navigate(`edit`)} type="link">
                                Modifica
                            </Button>
                        </PermissionRequired>
                    </Divider>
                    <Descriptions layout={"vertical"} size="small" colon={false} items={items} />
                    <Divider orientation="left">
                        Scadenze
                        <PermissionRequired name={"vehicle.update"}>
                            {" "}
                            <Button onClick={() => navigate(`editDueDates`)} type="link">
                                Modifica
                            </Button>
                        </PermissionRequired>
                    </Divider>
                    <Descriptions layout={"vertical"} colon={false}>
                        <Descriptions.Item label="Prossimo bollo">
                            {dayjs(data.taxDueDate).format("DD/MM/YYYY")}
                        </Descriptions.Item>
                        <Descriptions.Item label="Prossima revisione">
                            {dayjs(data.inspectionDueDate).format("DD/MM/YYYY")}
                        </Descriptions.Item>
                    </Descriptions>
                    <Divider orientation="left">
                        Stato
                        <PermissionRequired name={"vehicle.status_sync"}>
                            <Button
                                type="link"
                                icon={<SyncOutlined />}
                                loading={syncLoading}
                                onClick={() => {
                                    setSyncLoading(true);
                                    axiosInstance.post(`vehicle/${id}/syncStatus`).finally(() => {
                                        setSyncLoading(false);
                                        refresh();
                                    });
                                }}
                            >
                                Sincronizza
                            </Button>
                        </PermissionRequired>
                    </Divider>
                    <Descriptions layout={"vertical"} colon={false}>
                        <Descriptions.Item label="Odometro">
                            <div>
                                {data.odometer ?? 0} KM
                                <br />
                                <div style={{ color: "gray", fontSize: "0.85em" }}>
                                    {data.lastOdometerUpdateTs && (
                                        <>
                                            Aggiornato{" "}
                                            {dayjs(data.lastOdometerUpdateTs).format(
                                                "DD/MM/YYYY HH:mm",
                                            )}
                                            )
                                        </>
                                    )}
                                </div>
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="Ore motore">
                            <div>
                                {data.hours ?? 0} ore <br />
                                <div style={{ color: "gray", fontSize: "0.85em" }}>
                                    {data.lastHoursUpdateTs && (
                                        <>
                                            (Aggiornato{" "}
                                            {dayjs(data.lastHoursUpdateTs).format(
                                                "DD/MM/YYYY HH:mm",
                                            )}
                                            )
                                        </>
                                    )}
                                </div>
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        </Spin>
    );
}
