import {Avatar, Dropdown, message, Spin} from "antd";
import {CloudDownloadOutlined, UploadOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import axiosInstance from "../api"
import { downloadFile } from "../util";
export default function ({id, hasImg}) {
    const [image, setImage] = useState();
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();

    const handleFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        console.log(fileObj)
        if (!fileObj) {
            return;
        }
        const formData = new FormData();
        formData.append("file", fileObj);
        axiosInstance.post(`vehicle/${id}/picture`, formData)
            .then(() => setImage(URL.createObjectURL(fileObj)))
            .then(() => message.success("Immagine caricata con successo"));
    };

    console.log(hasImg)
    useEffect(() => {
        if (hasImg) {
            setLoading(true)
            axiosInstance.get(`vehicle/${id}/picture`, {responseType: "blob"})
                .then((response) => setImage(URL.createObjectURL(response.data)))
                .finally(() => setLoading(false))
        }
    }, [hasImg]);

    const downloadItem = {
        key: '1',
        label: 'Scarica',
        label: <a onClick={() => downloadFile(`vehicle/${id}/picture`)}>Scarica</a>,
        icon: <CloudDownloadOutlined/>
    };
    return <>
        <input
            style={{display: 'none'}}
            ref={inputRef}
            type="file"
            accept={".png,.jpg,.jpeg"}
            onChange={handleFileChange}
        />
        <Dropdown

            menu={{
                items: [
                    image && downloadItem,
                    {
                        key: '2',
                        label: <a onClick={() => inputRef.current.click()}>Carica</a>,
                        icon: <UploadOutlined/>
                    }].filter(x => x),
            }}
        >
            <Avatar src={image} style={{cursor: "pointer"}} 
                shape={'square'}
                size={{ xxl: 300, xl: 300, lg: 250, md: 300, sm: 300, xs: 300}}
            >
                {loading ? <Spin spinning={true}/> : (!image ? <span>Nessuna immagine</span> : '')}
            </Avatar>
        </Dropdown>
    </>
}
