import { useEffect, useState } from "react";
import { Spin } from "antd";
import { keycloack } from "./keycloak";

export default function CheckLogin(props) {
    const [authenticated, setAuthenticated] = useState(false);
    useEffect(() => {
        keycloack().then(() => setAuthenticated(true));
    }, []);
    return authenticated ? (
        props.children
    ) : (
        <div
            style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size={"large"} spinning={true} />
        </div>
    );
}
