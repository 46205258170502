import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "../api";
import { filterOption } from "../util";

export default function () {
    const [form] = Form.useForm();
    const [sp, setSp] = useSearchParams();
    const [categories, setCategories] = useState([]);
    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        axiosInstance
            .get("vehicleCategory")
            .then(({ data }) =>
                setCategories(data.map((x) => ({ label: x.name, value: "" + x.id }))),
            );
        axiosInstance
            .get("employee")
            .then(({ data }) =>
                setDrivers(data.map((x) => ({ label: x.fullName, value: "" + x.id }))),
            );
    }, []);

    useEffect(() => {
        form.setFieldsValue(
            Array.from(sp.entries()).reduce((prev, curr) => ({ ...prev, [curr[0]]: curr[1] }), {}),
        );
    }, [sp]);

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={(f) => {
                Array.from(sp.keys()).forEach((x) => sp.delete(x));
                Object.entries(f)
                    .filter(([_, v]) => !!v)
                    .forEach(([k, v]) => sp.set(k, v));
                setSp(sp);
            }}
        >
            <Row>
                <Col flex="none">
                    <Space wrap>
                        <Form.Item name="query" label="Parola chiave">
                            <Input style={{ width: 200 }} allowClear />
                        </Form.Item>
                        <Form.Item name="category" label="Categoria">
                            <Select
                                style={{ width: 200 }}
                                options={categories}
                                showSearch
                                allowClear
                                filterOption={filterOption}
                            />
                        </Form.Item>
                        <Form.Item name={"driver"} label="Autista">
                            <Select
                                style={{ width: 200 }}
                                options={drivers}
                                showSearch
                                allowClear
                                filterOption={filterOption}
                            />
                        </Form.Item>
                        <div style={{ minWidth: "100%" }}></div>
                    </Space>
                </Col>
                <Col flex="auto" style={{ textAlign: "end", alignContent: "end" }}>
                    <Space wrap>
                        <Form.Item>
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    Array.from(sp.keys()).forEach((x) => sp.delete(x));
                                    setSp(sp);
                                }}
                                style={{ width: 100 }}
                            >
                                Reset
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" style={{ width: 100 }} type="primary">
                                Applica
                            </Button>
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
}
