import {Button} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import {useNavigate} from "react-router-dom";


export default function ({title,backEnabled, level, padding}) {
    const navigate = useNavigate();
    const l = level ?? 3
    const pad = padding ?? 24;
    const be = backEnabled === undefined ? true : backEnabled;
    return <div style={{display: "flex", columnGap: 5, alignItems: "center"}}>
        {be &&<Button type={"text"} icon={<ArrowLeftOutlined/>} onClick={() => navigate(-1)}/>}
        <Title level={l} style={{margin: 0, paddingTop: pad, paddingBottom: pad}}>{title}</Title>
    </div>
}